<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>What type of quantity (length, volume density, etc.) do the following units indicate?</p>

      <v-simple-table>
        <thead>
          <tr>
            <th>Unit</th>
            <th>Length</th>
            <th>Area</th>
            <th>Volume</th>
            <th>Mass</th>
            <th>Density</th>
            <th>Time</th>
            <th>Temperature</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><stemble-latex content="$\text{kL}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$\text{nm}^2$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$\text{dm}^3$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$\text{mg/L}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$\text{fs}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$\mu\text{m}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td><stemble-latex content="$\text{mg}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="len" value="len" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="area" value="area" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="vol" value="vol" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="mass" value="mass" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="dens" value="dens" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="time" value="time" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="temp" value="temp" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question187',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
    };
  },
};
</script>
